import React from 'react';
import Quincek from 'components/results/Quincek';
import Sietek from 'components/results/Sietek';

const Results = () => {
    return (
        <>
            {/*<Quincek />*/}
            <hr />
            {/*<Sietek />*/}
        </>
    );
};

export default Results;